import { Breadcrumbs, Button, Checkbox, Divider, InputField, Tooltip, Typography } from 'components';
import { ROUTES } from 'consts';
import * as yup from 'yup';
import styles from './TicketsAllocation.module.scss';
import { useMemo, useState } from 'react';
import { HelpCircleIcon, TicketSmallActive, TicketSmallDisabled } from 'assets/svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {useParams} from "react-router";
import {useAllocateTicketMutation, useGetMyEventsQuery} from "../../store";
import AttendeesItem from "./Components/AttendeesItem";

export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

interface AllocateForm {
  name: string;
  email: string;
  confirmEmail: string;
}


const TicketsAllocation= () => {
  const { id } = useParams();
  const { data, refetch } = useGetMyEventsQuery(undefined, { refetchOnMountOrArgChange: true });
  const [allocateTicket, { isLoading, error }] = useAllocateTicketMutation();
  const events = data ?? [];
  const currentEvent = events.filter(event => {
   return event.id == id;
  });

  const tickets = currentEvent.length > 0 && currentEvent[0].tickets ? currentEvent[0].tickets : [];
  const ticketsCount = tickets ? tickets.length : 1;
  const allocatedTickets = tickets.filter( (ticket) => {
    if(ticket.attendeeEmail){
      return true;
    }
    return false;
  })
  // const numberOfTickets = 6;
  //const [allocatedTickets, setAllocatedTickets] = useState(ticketsCount);
  // when should this page be accessible
  const [checked, setIsChecked] = useState(false);

  const schema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .min(1, 'Name must be at least 1 character long.')
          .max(100, 'Name cannot exceed 100 characters.')
          .required('Name is required'),
        email: yup
          .string()
          .trim()
          .required('Please enter your email address to continue.')
          .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
        confirmEmail: yup
          .string()
          .trim()
          .matches(EMAIL_REGEX, 'Please enter a valid email address.')
          .oneOf([yup.ref('email')], 'Please confirm your email')
          .required('Please confirm your email')
      }),
    [],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AllocateForm>({ mode: 'all', resolver: yupResolver(schema) });

  const onSubmit = async (data: AllocateForm) => {
    if (!id) return;

    try {
      await allocateTicket({
        eventId: id,
        email: data.email,
        name: data.name,
        under18: checked,
      }).unwrap();
      refetch();
      console.log('Ticket allocated successfully');
    } catch (err) {
      console.error('Error allocating ticket:', err);
    }
  };

  return (
    <div>
      <div className="container">
        <Breadcrumbs title="My events" to={ROUTES.MY_EVENTS} />
      </div>
      <div className={styles.content}>
        <div className="m-b-48">
          <Typography variant="h4" weight="semibold" className="m-b-12">
            Who's coming?
          </Typography>
          <Typography variant="bodyS" color="gray300">
            To assign your tickets, please enter the name and email address of each additional attendee. They will claim
            the ticket via their email - so be sure to double check you've got it right!
          </Typography>
        </div>
        <div className={styles.contentAllocated}>
          <Typography variant="bodyS" color="gray300" weight="semibold" className="m-b-8">
            {allocatedTickets.length}/{ticketsCount} tickets assigned
          </Typography>
          <div className={styles.contentAllocatedWrap}>
            <div className={styles.contentAllocatedProgress} style={{ width: `${(allocatedTickets.length / ticketsCount) * 100}%` }} />
          </div>
        </div>
        {allocatedTickets.length < ticketsCount && (
            <>
              <form className={styles.contentAllocatedInputs} onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Controller
                      name="name"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                          <InputField
                              label="Attendee’s Name"
                              placeholder="Enter attendee's name"
                              className="m-b-16"
                              onChange={onChange}
                              onBlur={onBlur}
                              invalid={isTouched && invalid}
                              errorText={error?.message}
                              value={value}
                          />
                      )}
                  />
                </div>
                <div></div>
                <div>
                  <Controller
                      name="email"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                          <InputField
                              label="Attendee’s Email"
                              placeholder="Enter attendee's email"
                              onChange={onChange}
                              onBlur={onBlur}
                              invalid={isTouched && invalid}
                              errorText={error?.message}
                              value={value}
                          />
                      )}
                  />
                </div>
                <div>
                  <Controller
                      name="confirmEmail"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                          <InputField
                              label="Confirm Attendee’s Email"
                              placeholder="Confirm attendee's email"
                              onChange={onChange}
                              onBlur={onBlur}
                              invalid={isTouched && invalid}
                              errorText={error?.message}
                              value={value}
                          />
                      )}
                  />
                </div>
                <div style={{display: 'none'}}>
                  <Checkbox
                      isChecked={checked}
                      onChange={(e) => setIsChecked((e.target as HTMLInputElement).checked)}
                  >
                    <div className={styles.checkbox18Content}>
                      <div>Attendee is under 18 years old</div>
                      <Tooltip
                          className={styles.helpIcon18Tooltip}
                          content={
                            <Typography variant="bodyXs">
                              Some text
                            </Typography>
                          }
                          placement="top-start"
                          withArrow
                          offset={[0, 20]}
                      >
                        <HelpCircleIcon className={styles.helpIcon18} />
                      </Tooltip>
                    </div>
                  </Checkbox>
                </div>
              </form>
              <div className="text-right">
                <Button disabled={!isValid || isLoading} variant="primary" onClick={handleSubmit(onSubmit)}>
                  {isLoading ? 'Allocating...' : 'Allocate Ticket'}
                </Button>
              </div>
            </>
        )}


        <Divider className='m-b-32 m-t-32' />
        <Typography variant='bodyL' className='m-b-24'>Attendees</Typography>
        <div className={styles.attendeesList}>
          {tickets.map( (ticket) => {
            return (<AttendeesItem key={ticket.id} ticket={ticket} />)
          })}

        </div>
      </div>
    </div>
  );
};

export default TicketsAllocation;
