import {TicketSmallActive, TicketSmallDisabled} from 'assets/svg';
import {Button, ButtonLink, Typography} from 'components';
import styles from "../TicketsAllocation.module.scss";
import {ITicket} from "../../../types/ticket";


interface ITicketProps {
    ticket: ITicket
}

const AttendeesItem: React.FC<ITicketProps>  = ({ticket}) => {
    const {attendeeEmail, attendeeName, ownerId, } = ticket;
    return (
        <div className={styles.attendeesItem}>
            <div className={styles.attendeesItemUser}>
                <Typography variant='bodyS'>{attendeeName}</Typography>
                <Typography variant='bodyS' color='gray300'>{attendeeEmail}</Typography>
            </div>
            <div className={styles.resendAllocation}>
                {!ownerId && attendeeEmail && (<Button variant='link' size='XS'>Resend allocation</Button>)}
            </div>
            <div className={styles.ticket}>
                {!ownerId ? (<TicketSmallDisabled />) : (<TicketSmallActive />)}
            </div>
        </div>
    );
}

export default AttendeesItem;
