import cn from 'classnames';
import { Typography, Link } from 'components';
import styles from './Footer.module.scss';
import { FacebookGrayIcon, InstagramIcon, LocationIcon, LogoIcon, TiktokIcon, XIcon } from 'assets/svg';
import { ROUTES } from 'consts';
import { Link as NavLink } from 'react-router';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={cn(styles.footer, 'container')}>
      <section className={styles.footerTop}>
        <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
          <LogoIcon width={163} height={62} />
        </NavLink>
        <div className={styles.footerIcons}>
          <Link to="#">
            <InstagramIcon />
          </Link>
          <Link to="#">
            <TiktokIcon />
          </Link>
        </div>
      </section>
      <section className={cn(styles.footerLocations, 'p-y-32')}>
        <Typography variant="bodyS" weight="semibold" className={styles.footerLocationsTitle}>
          Address:
        </Typography>
        <ul className={styles.footerLocationsList}>
          <li>
            <LocationIcon /> <Typography variant="bodyS">603, 111 Piccadilly, Manchester, M1 2HY</Typography>
          </li>
        </ul>
      </section>
      <section className={styles.footerCopyright}>
        <Typography variant="bodyS">© {currentYear} ENTX Limited. All rights reserved.</Typography>
        <div className={styles.footerCopyrightLinks}>
          <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
          <Link to={ROUTES.TERMS_OF_SERVICE} className="m-l-24">
            Terms of Service
          </Link>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
