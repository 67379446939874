import {FC, memo, useCallback, useMemo, useRef} from 'react';
import {BitmovinPlayer, CustomUi} from 'bitmovin-player-react';
import {detect} from "detect-browser";
import {
  ControlBar,
  FullscreenToggleButton,
  PlaybackSpeedSelectBox,
  PlaybackTimeLabel,
  PlaybackToggleButton,
  PlaybackToggleOverlay,
  SeekBar,
  SettingsPanel,
  SettingsPanelItem,
  SettingsPanelPage,
  SettingsToggleButton,
  UIContainer,
  UIVariant,
  VideoQualitySelectBox,
  VolumeToggleButton
} from 'bitmovin-player-ui';
import {PlayerAPI, PlayerConfig, PlayerEvent, SourceConfig, PlayerType, StreamType} from 'bitmovin-player';
import styles from './Player.module.scss';
import SupportCreator from 'components/SupportCreator/SupportCreator';
import BitmovinComponentCreator from './components/BitmovinComponentCreator/BitmovinComponentCreator';
import {StationIcon} from 'assets/svg';
import Typography from 'components/Typography/Typography';
import {IEvent} from 'types';
import {StreamStatus} from 'enums';

interface PlayerProps {
  event: IEvent;
}

const LiveLabel = new BitmovinComponentCreator({
  children: <div className={styles.liveLabelWrapper}>
    <StationIcon /> Live
  </div>,
  cssClass: 'liveLabel'
});

const Player: FC<PlayerProps> = memo(({
  event
}) => {
  const playerRef = useRef<PlayerAPI>(null);
  const browser = detect()
  const defaultPlayerSource: SourceConfig = useMemo(() => ({
    dash: event.stream?.status === StreamStatus.FINISHED ? undefined : event.stream?.dashManifestUrl,
    hls: browser?.name === 'safari' ? undefined : event.stream?.hlsManifestUrl,
  }), []);

  const sourceLoad = async () => {
    const player = playerRef.current;
    if (!player) return;
    try {
      await player.unload();
      await player.load(defaultPlayerSource);
    } catch (e) {
      console.log(e);
    }
  };

  const SupportArtistElement = useMemo(() => new BitmovinComponentCreator({
    children: <SupportCreator artist={event.artists[0]} />,
    cssClass: 'supportCreator'
  }), [])

  const InfoElement = useMemo(() => new BitmovinComponentCreator({
    children: <div className={styles.infoElementWrapper}>
      <Typography variant='h5' color='gray25' className='m-b-8'>{event.title}</Typography>
      {event.stream?.status !== StreamStatus.FINISHED ? (
        <Typography variant='bodyMd' color="gray100" className='m-b-4'>Streaming live from {event.venue}</Typography>
      ) : null}
      {/* <div className={styles.watchBlock}>
        <ShowIcon className={styles.showIcon} />
        <Typography variant="bodyMd" color="gray100">
          95K watching
        </Typography>
      </div> */}
    </div>,
    cssClass: 'infoElement'
  }), [event]);

  let settingsPanel = useMemo(() => new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem('Video Quality', new VideoQualitySelectBox({ translator: (item) => `${item.key.split('_')[0]}${item.key.includes('_') ? "p" : ""}` })),
          ...(event.stream?.status === StreamStatus.FINISHED ? [new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox())] : []),
        ],
      }),
    ],
    hidden: true,
  }), [event]);

  const uiVariantsFactory = useCallback((): UIVariant[] => [
    {
      ui: new UIContainer({
        components: [
          new PlaybackToggleOverlay(),
          ...(event.artists[0] ? [SupportArtistElement] : []),
          InfoElement,
          new ControlBar({
            components: [
              settingsPanel,
              new SeekBar(),
              new PlaybackToggleButton(),
              new VolumeToggleButton(),
              new PlaybackTimeLabel(),
              new FullscreenToggleButton(),
              // LiveLabel,
              new SettingsToggleButton({ settingsPanel: settingsPanel }),
            ],
            hidden: false,

          }),
        ],
      }),
      // condition: context => context.isFullscreen,
    },
  ], [settingsPanel, SupportArtistElement, InfoElement]);

  const customUi: CustomUi = useMemo(() => ({
    variantsFactory: uiVariantsFactory,
  }), [uiVariantsFactory]);

  const playerConfig: PlayerConfig = useMemo(() => ({
    key: process.env.REACT_APP_BITMOVIN_KEY || "",
    playback: {
      autoplay: event.stream?.status !== StreamStatus.FINISHED,
      muted: event.stream?.status !== StreamStatus.FINISHED,
      preferredTech: [
          { player: PlayerType.Html5, streaming: StreamType.Hls },
        { player: PlayerType.Html5, streaming: StreamType.Dash },
        { player: PlayerType.Native, streaming: StreamType.Hls },
      ],
      adaptation: {
        preload: true
      },
    },
    events: {
      [PlayerEvent.Error]: (event: any) => {
        console.log('Error', event);
        if (event.name === "SOURCE_INVALID") {
          setTimeout(sourceLoad, 10000);
        }
      },
    },
  }), []);
  return (
    <div className={styles.wrapper}>
      <BitmovinPlayer playerRef={playerRef} customUi={customUi} source={defaultPlayerSource} config={playerConfig} />
    </div>
  );
});

export default Player;

