import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'components';
import { notify } from 'helpers';
import { FC, ReactNode, useState } from 'react';
import styles from './StripePayment.module.scss';
import cn from 'classnames';

interface PaymentFormProps {
  clientSecret: string;
  onSubmit: () => void;
  buttonText?: string;
  additionalButton?: ReactNode;
  buttonsClassName?: string;
  addressElement?: boolean;
  isActive?: boolean;
}

const PaymentForm: FC<PaymentFormProps> = ({
  onSubmit,
  clientSecret,
  buttonText,
  additionalButton,
  buttonsClassName,
  addressElement,
}) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }
    setLoading(true);
    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error || !paymentIntent) {
      notify.error(error?.message || 'Something went wrong');
      setLoading(false);
    } else {
      setLoading(false);
      onSubmit();
    }
  };

  return (
    <>
      <PaymentElement />
      {addressElement && <AddressElement options={{ mode: 'billing' }} />}
      <div className={cn(styles.paymentButtons, buttonsClassName)}>
        {additionalButton || null}
        <Button variant="primary" size="M" className={styles.payButton} loading={loading} onClick={handleSubmit}>
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default PaymentForm;
