import { Modal, Typography } from 'components';
import styles from './StreamRulesModal.module.scss';

interface UnverifiedUserModalProps {
    visible: boolean;
    closeModal: () => void;
}

const StreamRulesModal: React.FC<UnverifiedUserModalProps> = ({ visible, closeModal }) => {
    const onOk = () => {
        closeModal();
    };

    return (
        <Modal
            visible={visible}
            title="Rules of Streaming"
            closable={false}
            cancelText={null}
            okText="Got It!"
            onOk={onOk}>
            <div className="m-b-16">
                <ul className={styles.rules}>
                    <li className={styles.rulesLi}>
                        Any VPN services must be disabled
                    </li>
                    <li className={styles.rulesLi}>
                        Single user access can be used on one device at a time
                    </li>
                    <li className={styles.rulesLi}>
                        Copyright laws apply (including no recording and / or redistribution of any part of the livestream or on-demand content)
                    </li>
                    <li className={styles.rulesLi}>
                        Commercial applications are prohibited unless given explicit permission
                    </li>
                    <li className={styles.rulesLi}>
                        Violations of rules of streaming can result in legal action
                    </li>
                </ul>
            </div>
        </Modal>
    );
};

export default StreamRulesModal;
