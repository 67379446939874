import { Modal, Typography } from 'components';
import { useAppNavigate } from 'hooks';

interface UnverifiedUserModalProps {
  visible: boolean;
  closeModal: () => void;
  handleOk: () => void;
}

const UnverifiedUserModal: React.FC<UnverifiedUserModalProps> = ({ visible, closeModal, handleOk }) => {
  const onOk = () => {
    closeModal();
    handleOk()
  };

  return (
    <Modal
      visible={visible}
      title="Action Required"
      closable={false}
      cancelText={null}
      okText="Got it, Continue"
      onOk={onOk}>
      <div className="m-b-16">
        <Typography variant="bodyS" weight="medium" color="primary500" className="m-b-8">
          STEP 1
        </Typography>
        <Typography variant="bodyS" weight="semibold" className="m-b-4">
          Payment pre-authorization
        </Typography>
        <Typography variant="bodyS" color="gray300">
          We’ll reserve the amount, but you won’t be charged yet.
        </Typography>
      </div>
      <div className="m-b-16">
        <Typography variant="bodyS" weight="medium" color="primary500" className="m-b-8">
          STEP 2
        </Typography>
        <Typography variant="bodyS" weight="semibold" className="m-b-4">
          ID verification
        </Typography>
        <Typography variant="bodyS" color="gray300">
          Verify your identity to secure your ticket.
        </Typography>
      </div>
      <div className="m-b-16">
        <Typography variant="bodyS" weight="medium" color="primary500" className="m-b-8">
          STEP 3
        </Typography>
        <Typography variant="bodyS" weight="semibold" className="m-b-4">
          Event purchase
        </Typography>
        <Typography variant="bodyS" color="gray300">
          Receive your ticket and complete payment.
        </Typography>
      </div>
    </Modal>
  );
};

export default UnverifiedUserModal;
