import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { toast } from 'react-toastify';
import { Link, Modal, Typography } from 'components';
import { ArrowBackIcon, CheckVerifiedIcon, LogoIcon } from 'assets/svg';
import AboutInfoStep from './components/AboutInfoStep/AboutInfoStep';
import VerifyEmailStep from './components/VerifyEmailStep/VerifyEmailStep';
import LoginStep from './components/LoginStep/LoginStep';
import { notify } from 'helpers';
import { useAppNavigate } from 'hooks';
import styles from './Login.module.scss';

enum LoginSteps {
  LOGIN,
  VERIFY_EMAIL,
  ABOUT,
}

interface LoginProps {
  isSignUp?: boolean;
}
const Login: FC<LoginProps> = ({ isSignUp = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigateTo } = useAppNavigate();
  const [isSuccessModalOpened, openSuccessModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<LoginSteps>(LoginSteps.LOGIN);

  const onSuccessEmailContinue = async () => {
    setCurrentStep(LoginSteps.VERIFY_EMAIL);
  };

  const onRegisterRequire = () => {
    setCurrentStep(LoginSteps.ABOUT);
  };

  const backToLogin = () => {
    setCurrentStep(LoginSteps.LOGIN);
  };

  const onSuccessSignUp = () => {
    openSuccessModal(true);
  };

  useEffect(() => {
    if (searchParams.get('callbackError') && !toast.isActive('callbackError')) {
      notify.error('There is problem with login. Please try again later', undefined, undefined, 'callbackError');
      setTimeout(() => {
        searchParams.delete('callbackError');
        setSearchParams(searchParams);
      }, 0);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Modal
        visible={isSuccessModalOpened}
        closable={false}
        title="🪐  Welcome to ENTX"
        cancelText={null}
        onOk={() => navigateTo('EVENTS')}
        okText={'Start Exploring'}>
        <div className={styles.welcomeModalBody}>
          <CheckVerifiedIcon className={styles.welcomeModalBodyIcon} />
          <div className={styles.welcomeModalBodyText}>
            <Typography className="m-b-4" variant="bodyS" weight="bold" color="white">
              Complete ID verification
            </Typography>
            <Typography variant="bodyS" color="gray300">
              Your profile will be fully verified when you complete the KYC process during your first stream or ticket
              purchase. This ensures a secure and trusted experience for everyone.
            </Typography>
          </div>
        </div>
      </Modal>
      <header className={styles.header}>
        <div className={styles.headerRow}>
          <LogoIcon />
        </div>
      </header>
      <main className={styles.main}>
        {[LoginSteps.LOGIN, LoginSteps.VERIFY_EMAIL].includes(currentStep) ? (
          <>
            <div className={styles.leftPart}>
              {currentStep === LoginSteps.LOGIN ? (
                <div className={styles.infoWrapper}>
                  <LoginStep onSuccessEmailContinue={onSuccessEmailContinue} isSignUp={isSignUp} />
                </div>
              ) : null}
              {currentStep === LoginSteps.VERIFY_EMAIL ? (
                <div className={styles.infoWrapper}>
                  <ArrowBackIcon onClick={backToLogin} className={styles.backIcon} />
                  <VerifyEmailStep onRegisterRequire={onRegisterRequire} />
                </div>
              ) : null}
            </div>
            <div className={styles.rightPart} style={{ backgroundImage: "url('/auth-img.png')"}}>
            </div>
          </>
        ) : (
          <div className={styles.aboutInfoWrapper}>
            <AboutInfoStep onSuccessSignUp={onSuccessSignUp} />
          </div>
        )}
      </main>
    </div>
  );
};

export default Login;
